import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import { SchoolUsersAPI, UserAPI } from "src/API";
import NewUserForm from "./NewUserForm";

interface INewUserScreenProps {
  appActions: any;
  schoolActions: any;
  history: any;
  schoolState: any;
}

interface INewUserScreenState {
  loading: boolean;
  families: any;
  selectedFamily: number | string;
  newFamilyName: string;
  mode: string;
  user: INewUser;
  createdUsers: INewUser[];
}

interface INewUser {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
  dob: moment.Moment;
  addressId: number;
  addressStreet?: string;
  addressCity?: string;
  addressState?: string;
  addressZip?: string;
  role: string;
  familyId: any;
}

const blankUser: INewUser = {
  id: 0,
  firstName: "",
  lastName: "",
  emailAddress: "",
  phone: "",
  dob: moment(),
  addressId: 0,
  addressStreet: "",
  addressCity: "",
  addressState: "AL",
  addressZip: "",
  role: "student",
  familyId: 0,
};

class NewUserScreen extends React.Component<INewUserScreenProps, INewUserScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      mode: "primary",
      families: [],
      selectedFamily: 0,
      newFamilyName: "",
      user: blankUser,
      createdUsers: [],
    };

    this.updateField = this.updateField.bind(this);
    this.fetchFamilies = this.fetchFamilies.bind(this);
    this.createFamily = this.createFamily.bind(this);
    this.updateUserField = this.updateUserField.bind(this);
    this.validate = this.validate.bind(this);
    this.finished = this.finished.bind(this);
    this.saveAndNew = this.saveAndNew.bind(this);
  }

  public componentDidMount() {
    this.fetchFamilies();
  }

  public render() {
    return (
      <div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <Card title="New User Wizard">
              This screen will help you create one or more new user accounts for your school. Users are grouped into families, so the first decision is whether the new user(s)
              will belong to an existing or a new family. Next, enter the information for the user. You can choose to add as many users as you would like.
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            {this.state.createdUsers.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <Card title={`Created ${this.state.createdUsers.length} Users`}>
                    The following users have been created:
                    <ol>
                      {this.state.createdUsers.map((user, i) => {
                        return (<li key={i}>{user.firstName} {user.lastName} ({user.role})</li>);
                      })}
                    </ol>
                    <button className="btn block btn-primary" onClick={this.finished}>I am Finished</button>
                  </Card>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <Card title="Family" loading={this.state.loading} help="Families represent a group of users. All users must belong to one (and only one) family. If a user needs to be moved to a different family, please contact support.">
                  <div className="form-group">
                    <label>Select a Familiy</label>
                    <select id="selectedFamily" className="form-control" value={this.state.selectedFamily} onChange={this.updateField}>
                      <option value="0" disabled={true}>Select a Family</option>
                      <option value="-1">---- CREATE NEW FAMILY ----</option>
                      {this.state.families.map((f: any) => {
                        return (<option key={f.id} value={f.id}>{f.name}</option>);
                      })}
                    </select>
                  </div>
                  {this.state.selectedFamily === "-1" ?
                    (
                      <div>
                        <div className="form-group">
                          <label>New Family Name</label>
                          <input type="text" id="newFamilyName" className="form-control" value={this.state.newFamilyName} onChange={this.updateField} />
                        </div>
                        <div className="form-group">
                          <button className="btn btn-block btn-success" onClick={this.createFamily}>Create New Family</button>
                        </div>
                      </div>) :
                    (null)}
                </Card>
              </div>
            </div>
          </div>
          {this.state.selectedFamily !== "-1" && this.state.selectedFamily !== -1 && this.state.selectedFamily !== "0" && this.state.selectedFamily !== 0 && (
            <div className="col-md-8">
              <Card title="New User Info" loading={this.state.loading} help="If the student is a minor, this should be the guardian's information. Otherwise, this is the student.">
                <NewUserForm
                  role="primary"
                  onChange={this.updateUserField}
                  user={this.state.user}
                  onSave={this.saveAndNew} />
              </Card>
            </div>
          )}
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateUserField(key: string, value: any) {
    const ns: any = this.state.user;
    ns[key] = value;
    this.setState({ user: ns });
  }

  private async fetchFamilies() {
    this.setState({ loading: true }, async () => {
      try {
        const famRes = await SchoolUsersAPI.getFamiliesInSchool(this.props.schoolState.school.id);
        this.setState({ loading: false, families: famRes.body.data });
      } catch (err) {
        this.setState({ loading: false }, () => {
          error("Could not fetch families");
        });
      }
    });
  }

  private async createFamily() {
    let familyId = this.state.selectedFamily;
    this.setState({ loading: true }, async () => {
      try {
        const famRes = await SchoolUsersAPI.createFamily(this.props.schoolState.school.id, { name: this.state.newFamilyName });
        familyId = famRes.body.data.id;
        // add it to the select
        const fams = this.state.families;
        fams.push({
          headAccount: 0,
          id: familyId,
          name: this.state.newFamilyName,
          schoolId: this.props.schoolState.school.id
        });
        this.setState({
          selectedFamily: familyId,
          families: fams,
          loading: false
        });
      } catch (err) {
        return error("We could not save that family");
      }
    });
  }

  private validate() {
    // validate the fields
    if (this.state.selectedFamily === 0) {
      error("Please select or create a family first");
      return false;
    }
    // address only matters if addressId is 0
    if (this.state.user.firstName === "" ||
      this.state.user.lastName === "" ||
      this.state.user.emailAddress === "" ||
      this.state.user.phone === "" ||
      this.state.user.dob === moment()) {
      error("All fields are required for the user");
      return false;
    }
    if (this.state.user.addressId === 0 && (
      this.state.user.addressStreet === "" ||
      this.state.user.addressCity === "" ||
      this.state.user.addressZip === "")) {
      error("All fields are required for the primary user");
      return false;
    }
    return true;
  }

  private async saveAndNew() {
    const valid = this.validate();
    if (!valid) {
      return;
    }
    const familyId = typeof this.state.selectedFamily === "string" ? parseInt(this.state.selectedFamily, 10) : this.state.selectedFamily;
    // first, they may have forgotten to hit the family button
    if (familyId === -1) {
      return error("No family was created. Did you forget to hit the Create New Family button?");
    }
    this.setState({ loading: true }, async () => {
      // it's valid, so save the user, then update the fields and create a second user
      const user = this.state.user;
      user.familyId = familyId;
      let createdUserId = 0;
      const address = {
        street: this.state.user.addressStreet,
        city: this.state.user.addressCity,
        state: this.state.user.addressState,
        zip: this.state.user.addressZip,
      };
      const send: any = {
        ...user,
        dob: user.dob.format("YYYY-MM-DD")
      };
      let createdUser: any = {};
      try {
        const userRes = await SchoolUsersAPI.createNewSchoolUser(this.props.schoolState.school.id, send);
        createdUserId = userRes.body.data.id;
        createdUser = userRes.body.data;

      } catch (err) {
        error("We could not create the user");
        return this.setState({ loading: false });
      }

      await UserAPI.saveUserAddress(this.props.schoolState.school.id, createdUserId, address);

      if (this.state.createdUsers.length === 0) {
        // this is the first one so update the family
        try {
          // this user needs to be the head account
          await SchoolUsersAPI.updateFamily(this.props.schoolState.school.id, familyId, { headAccount: createdUserId });
        } catch (err) {
          error("We could not update the family");
          return this.setState({ loading: false });
        }
      }

      const createdUsers = this.state.createdUsers;
      createdUsers.push(createdUser);
      const newUser = blankUser;
      newUser.dob = moment();
      newUser.firstName = "";
      newUser.lastName = "";
      // we leave some fields but remove others
      success("User created!");
      this.setState({
        user: newUser,
        createdUsers,
        loading: false
      });
      // add the student to the student lists
      this.props.schoolActions.addUser(createdUser);
    });
  }

  private finished() {
    // we go to the last created users page
    this.props.history.push(`/schools/${this.props.schoolState.school.id}/users/${this.state.createdUsers[this.state.createdUsers.length - 1].id}`);
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewUserScreen) as React.ComponentType<any>);