import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { FinancesAPI, ScheduleAPI, SchoolUsersAPI, NewsAPI } from "../../../API/";
import { error } from "../../structure/Alert";
import BarGraph from "../../structure/BarGraph";
import NewsItem from "./NewsItem";

const start = moment().subtract(14, "days").format("YYYY-MM-DDT00:00:00")+"Z";
const end = moment().add(1, "day").format("YYYY-MM-DDT00:00:00Z");
const lastYearStart = moment().subtract(1, "year").format("YYYY-MM-DDT00:00:00")+"Z";

interface IDashboardProps {
  appActions: any;
  schoolState: any;
  history: any;
}

interface IDashboardState {
  loading: boolean;
  invoices: any;
  attendance: any;
  users: any;
  news: any;
}

class DashboardScreen extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      invoices: [],
      attendance: [],
      users: [],
      news: [],
    };

    this.fetchDashboardData = this.fetchDashboardData.bind(this);
  }

  public componentDidMount(){
    this.fetchDashboardData();
  }

  public render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <Card title="System News" loading={this.state.loading} help="Here you will find system-wide news that may be relevant to your usage of the application. Often, new features are announced here.">
            {this.state.news.map((news: any, index: number) => {
              return (
                <NewsItem
                  key={news.id}
                  id={news.id}
                  title={news.title}
                  body={news.body}
                  posted={moment(news.posted).format("MM/DD/YYYY")}
                  className={index % 2 === 0 ? "even" : "odd"}
                  />
              );
            })}
          </Card>
        </div>
        <div className="col-md-3">
          <Card title="Invoices Due" loading={this.state.loading} help="Below is a quick summary of the amount of money due via invoices over the previous timeframe.">
            <BarGraph data={this.state.invoices} hideLegend={true} />
          </Card>
        </div>
        <div className="col-md-3">
          <Card title="Attendance" loading={this.state.loading} help="Below is a quick summary of the school attendance over the previous timeframe.">
            <BarGraph data={this.state.attendance} hideLegend={true} />
          </Card>
        </div>
        <div className="col-md-3">
          <Card title="New Students" loading={this.state.loading} help="Below is a quick summary of the number of new students and users over the previous timeframe.">
            <BarGraph data={this.state.users} hideLegend={true} />
          </Card>
        </div>
      </div>
    );
  }

  private async fetchDashboardData(){
    // TODO: break this out into separate try catches so that a failure on one doesn't bork them all
    this.setState({loading: true}, async () => {    
      try{
        let schoolId = this.props.schoolState.school.id ? this.props.schoolState.school.id : 0;
        if(!schoolId || schoolId === 0){
          // there is a chance that the redux store has not been set, so we need to grab it from local storage
          schoolId = window.localStorage.schoolId ? window.localStorage.schoolId : 0;
        }
        const invRes = await FinancesAPI.getSchoolInvoiceDashboard(schoolId, start, end);
        const attRes = await ScheduleAPI.getEventsAttendanceDashboard(schoolId, start, end);
        const usersRes = await SchoolUsersAPI.getNewUsersDashboard(schoolId, lastYearStart, end);
        const newsRes = await NewsAPI.getNews();

        // now process the data
        const invoices: any = {
          data: [],
          total: 0
        };
        for(const i of invRes.body.data.data){
          i.label = moment(i.day).format("MM/DD");
          i.count = i.count / 100;
          invoices.data.push(i);
        }

        const attendance: any = {
          data: [],
          total: 0
        };
        for(const i of attRes.body.data.data){
          i.label = moment(i.day).format("MM/DD");
          i.count = i.count;
          attendance.data.push(i);
        }

        const users: any = {
          data: [],
          total: 0
        };
        for(const i of usersRes.body.data.data){
          i.label = moment(i.day).format("MM/YY");
          i.count = i.count;
          users.data.push(i);
        }

        this.setState({
          loading: false,
          invoices,
          attendance,
          users,
          news: newsRes.body.data
        });
      }catch(err){
        return this.setState({loading: false}, () => {
          error("Could not load the dashboard");
        });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardScreen) as React.ComponentType<any>);