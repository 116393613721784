import { makeCall,makeUrl } from "./client";

export class SchoolAPI {

  /**
   * Get the school's info
   * @param schoolId 
   * @param params 
   */
  public getSchoolInfo(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}`, params);
  }

  /**
   * Create a new school
   * @param data should have a user and a school property with the appropriate data
   * @param options 
   */
  public createNewSchool(data: any = {}, options: any = {}): Promise<any> {
    return makeCall("post", `signup`, data, options);
  }

  /**
   * Update the school's settings
   * @param schoolId 
   * @param params 
   */
  public updateSchoolSettings(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/settings`, params);
  }

  /**
   * Upload a new school logo
   * @param schoolId 
   * @param file 
   */
  public uploadSchoolLogo(schoolId: number, file: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/images/logo`, file, {asMultipartFile: true});
  }


  public uploadSchoolBanner(schoolId: number, file: any = {}): Promise<any> {
    return makeCall("post", `schools/${schoolId}/images/banner`, file, {asMultipartFile: true});
  }

  public getSchoolBranding(schoolId: number, brandType: "logo" | "banner", query: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/images/${brandType}`, query);
  }

  /**
   * Get the URL for the school's logo
   * @param schoolId 
   */
  public getSchoolLogoString(schoolId: number): string {
    return makeUrl(`schools/${schoolId}/images/logo`);
  }


  public getSchoolBannerString(schoolId: number): string {
    return makeUrl(`schools/${schoolId}/images/banner`);
  }


  /**
   * Update a school payment method
   * @param schoolId 
   * @param firstName 
   * @param lastName 
   * @param emailAddress 
   * @param token 
   * @param params 
   */
  public updateSchoolPaymentMethod(schoolId: number, firstName: string, lastName: string, emailAddress: string, token: string, params: any = {}): Promise<any> {
    const data = {
      ...params,
      firstName,
      lastName,
      emailAddress,
      token,
    };
    return makeCall("put", `schools/${schoolId}/settings/paymentMethod`, data);
  }

  /**
   * Delete a school payment method
   * @param schoolId 
   * @param params 
   */
  public deleteSchoolPaymentMethod(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/settings/paymentMethod`, params);
  }

  public getSetupSteps(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/setup/wizard`, params);
  }

  public updateAllSetupSteps(schoolId: number, status: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      status
    };
    return makeCall("patch", `schools/${schoolId}/setup/wizard`, params);
  }

  public updateSingleSetupStepByStepNumber(schoolId: number, stepNumber: number, status: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      status
    };
    return makeCall("patch", `schools/${schoolId}/setup/wizard/${stepNumber}`, params);
  }

  public updateSingleSetupStepByStepKey(schoolId: number, stepKey: string, status: string, params: any = {}): Promise<any> {
    params = {
      ...params,
      status
    };
    return makeCall("patch", `schools/${schoolId}/setup/wizard/${stepKey}`, params);
  }

}