import { makeCall, makeUrl } from "./client";

export class UserAPI {
  /**
   * Attempts to login a user
   * @param username 
   * @param password 
   */
  public loginUser(username: string, password: string): Promise<any> {
    return makeCall("post", "users/login", {username, password});
  }

  /**
   * Get the schools a user is a member of
   * @param userId 
   */
  public getUserSchools(userId: number): Promise<any> {
    return makeCall("get", `users/${userId}/schools`);
  }

  /**
   * Get a user profile
   * @param schoolId 
   * @param userId 
   */
  public getUserProfile(schoolId: number, userId: number): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}`);
  }

  /**
   * Update a user's profile
   * @param schoolId 
   * @param userId 
   * @param data 
   * @param options 
   */
  public saveUserProfile(schoolId: number, userId: number, data: any = {}, options: any = {}): Promise<any> {
    return makeCall("patch", `schools/${schoolId}/users/${userId}`, data, options);
  }

  /**
   * Save a user's address
   * @param schoolId 
   * @param userId 
   * @param params 
   */
  public saveUserAddress(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/address`, params);
  }

  /**
   * Uploade a new profile image using a multipart form
   * @param schoolId 
   * @param userId 
   * @param file 
   */
  public uploadUserProfileImage(schoolId: number, userId: number, file: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/images/profile`, file, {asMultipartFile: true});
  }

  /**
   * Get the URL for the user's profile image
   * @param schoolId 
   * @param userId 
   * @param asThumb 
   */
  public getUserProfileImageString(schoolId: number, userId: number, asThumb: boolean = false, addJWT: boolean = false): string {
    const thumbQuery = asThumb ? "thumb=1&" : "";
    let jwtQuery = "";
    if(addJWT){
      // grab the JWT from local storage and add it
      jwtQuery = `jwt=${window.localStorage.jwt}&`;
    }
    return makeUrl(`schools/${schoolId}/users/${userId}/images/profile?${thumbQuery}${jwtQuery}`);
  }

  /**
   * Get a list of users in a user's family
   * @param schoolId 
   * @param userId 
   * @param params 
   */
  public getUsersInFamily(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/family`, params);
  }

  /**
   * Verify a provided token for a username
   * @param username 
   * @param token 
   * @param params 
   */
  public verifyEmailToken(username: string, token: string, params: any = {}): Promise<any> {
    const data = {
      username,
      token
    };
    return makeCall("post", `verify`, data);
  }

  /**
   * Start the password reset process
   * @param username 
   * @param email 
   * @param params 
   */
  public resetPassword(username: string, email: string = "", params: any = {}): Promise<any> {
    const data = {
      username,
      email,
    };
    return makeCall("post", `users/reset`, data);
  }

  /**
   * Verify the password reset token
   * @param username 
   * @param token 
   * @param password 
   * @param params 
   */
  public verifyPasswordToken(username: string, token: string, password: string, params: any = {}): Promise<any> {
    const data = {
      username,
      token,
      password
    };
    return makeCall("post", `users/reset/verify`, data);
  }

}