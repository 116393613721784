import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";

import Card from "../../structure/Card";
import { error, success } from "../../structure/Alert";
import StateSelect from "../../structure/StateSelect";
import * as AppActions from "../../../reducers/appReducer";
import { SchoolsAPI } from "src/API";
import SchoolLogo from "../../structure/SchoolLogo";

interface ISchoolSettingsScreenProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface ISchoolSettingsScreenState {
  loading: boolean;
  school: ISchool;
}

interface ISchool {
  addressCity: string;
  addressState: string;
  addressStreet: string;
  addressZip: string;
  contractTerms: string;
  created: moment.Moment;
  id: number;
  kdojangFlat: number;
  kdojangPercent: number;
  name: string;
  paymentMethodType: string;
  paymentMethodToken: string;
  payoutInterval: string;
  payoutMethod: string;
  phone: string;
  plan: string;
  timezone: string;
  alertEmails: string;
}

const defaultSchool: ISchool = {
  addressCity: "",
  addressState: "NH",
  addressStreet: "",
  addressZip: "",
  contractTerms: "",
  created: moment(),
  id: 0,
  kdojangFlat: 0,
  kdojangPercent: 0,
  name: "",
  paymentMethodType: "card",
  paymentMethodToken: "",
  payoutInterval: "monthly",
  payoutMethod: "check",
  phone: "",
  plan: "basic",
  timezone: "America/New_York",
  alertEmails: "",
};

class SchoolSettingsScreen extends React.Component<ISchoolSettingsScreenProps, ISchoolSettingsScreenState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      school: defaultSchool
    };

    this.fetch = this.fetch.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateSchool = this.updateSchool.bind(this);
  }

  public componentDidMount(){
    this.fetch();
  }

  public render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-5">
            <Card title="Logo" loading={this.state.loading} help="You may upload a logo for your school here. It should be generally square or round. This logo will be used throughout the KDojang software suite.">
              <div className="form-group">
                <SchoolLogo
                  schoolId={this.props.schoolState.school.id}
                  mode="logo"
                  width="50%"
                  size="large"
                  useSensor={false}/>
                  <p className="small">Click the image to upload a new image</p>
              </div>
            </Card>
            <Card title="Banner" loading={this.state.loading} help="You may upload a bannder that is horizontal here. This will be used in areas where a square or round logo would not be as useful.">
              <div className="form-group">
                <SchoolLogo
                  schoolId={this.props.schoolState.school.id} 
                  mode="banner"
                  width="50%"
                  size="large"
                  useSensor={false}/>
                  <p className="small">Click the image to upload a new image</p>
              </div>
            </Card>
            <Card title="Contract Terms" loading={this.state.loading} help="You may add terms for each Contract here. They will automatically be added to all new contracts. Existing contracts will NOT be updated.">
              These terms will be added to new contracts by default.
              <div className="form-group">
                <textarea id="contractTerms" value={this.state.school.contractTerms} onChange={this.updateField} className="form-control" rows={20} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-success" onClick={this.updateSchool}>Update Contract Terms</button>
              </div>
            </Card>
          </div>
          <div className="col-md-7">
            <Card title="Settings" loading={this.state.loading} help="These are the basic settings for your school, including contact information, location, and email settings.">
              <div className="form-group">
                <label>School Name</label>
                <input type="text" id="name" value={this.state.school.name} onChange={this.updateField} className="form-control" />
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input type="text" id="phone" value={this.state.school.phone} onChange={this.updateField} className="form-control" />
              </div>
              <div className="form-group">
                <label>Street Address</label>
                <input type="text" id="addressStreet" className="form-control" value={this.state.school.addressStreet} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>City</label>
                    <input type="text" id="addressCity" className="form-control" value={this.state.school.addressCity} onChange={this.updateField} />
                  </div>
                  <div className="col-md-4">
                    <label>State</label>
                    <StateSelect id="addressState" selectedState={this.state.school.addressState} onSelect={this.updateField} />
                  </div>
                  <div className="col-md-4">
                    <label>Zip</label>
                    <input type="text" id="addressZip" className="form-control" value={this.state.school.addressZip} onChange={this.updateField} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Timezone</label>
                <select id="timezone" className="form-control" value={this.state.school.timezone} onChange={this.updateField}>
                  <option value="America/Puerto_Rico">Puerto Rico (Atlantic)</option>
                  <option value="America/New_York">New York (Eastern)</option>
                  <option value="America/Chicago">Chicago (Central)</option>
                  <option value="America/Denver">Denver (Mountain)</option>
                  <option value="America/Phoenix">Phoenix (MST)</option>
                  <option value="America/Los_Angeles">Los Angeles (Pacific)</option>
                  <option value="America/Anchorage">Anchorage (Alaska)</option>
                  <option value="Pacific/Honolulu">Honolulu (Hawaii)</option>
                </select>
              </div>
              <div className="form-group">
                <label>Alert Emails</label>
                <p>
                  <small>Enter which email addresses you want to receive emails regarding alerts, warnings, and activity. Separate multiple email addresses with a comma.</small>
                </p>
                <input type="text" id="alertEmails" className="form-control" value={this.state.school.alertEmails} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-success" onClick={this.updateSchool}>Save Settings</button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private fetch(){
    this.setState({loading: true}, async () => {
      try{
        const schoolRes = await SchoolsAPI.getSchoolInfo(this.props.schoolState.school.id);
        this.setState({loading: false, school: schoolRes.body.data});
      } catch(err){
        error("could not load the school settings");
        this.setState({loading: false});
      }
    });
  }

  private updateSchool(){
    this.setState({loading: true}, async () => {
      try{
        await SchoolsAPI.updateSchoolSettings(this.props.schoolState.school.id, this.state.school);
        success("Saved!");
        this.setState({loading: false});
      } catch(err){
        error("could not load the school settings");
        this.setState({loading: false});
      }
    });
  }

  private updateField(e: any){
    const school: ISchool = this.state.school;
    school[e.target.id] = e.target.value;
    this.setState({school});
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolSettingsScreen) as React.ComponentType<any>);